<template>
    <div class="text-wrapper">
        <div class="label">{{ label }}:</div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TextWrapper',
    props: {
        label: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="less" scoped>
.text-wrapper {
    height: 50px;
    background: #ffffff;
    opacity: 1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 8px 0 10px;
    margin-bottom: 10px;
    .label {
        font-size: 14px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
    }
    .content {
        flex: 1;
        height: 100%;
    }
}
</style>
