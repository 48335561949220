<template>
    <page title="评价填写" :hasBack="true" textAlign="left">
        <common-block title="基本信息">
            <text-wrapper class="has-line" label="姓名">
                <div class="des">{{ userMsg.username }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="性别">
                <div class="des">{{ userMsg.sex }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="身份证">
                <div class="des">{{ userMsg.idCard }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="班级">
                <div class="des">{{ userMsg.className }}</div>
            </text-wrapper>
        </common-block>
        <common-block title="相关联系人">
            <text-wrapper class="has-line" :label="`【父${userMsg.sex}】${userMsg.fatherName}`">
                <div class="des">{{ userMsg.fatherPhone }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" :label="`【母${userMsg.sex}】${userMsg.motherName}`">
                <div class="des">{{ userMsg.motherPhone }}</div>
            </text-wrapper>
        </common-block>
        <common-block class="reason" title="请假理由">
            <van-field v-model="comments" rows="4" autosize type="textarea" placeholder="请点击输入评价吧" />
        </common-block>
        <div class="btn-wrapper">
            <van-button class="confirm-btn" round type="primary">确认保存</van-button>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock';
import TextWrapper from '@/components/text/text';
import { Field, Button } from 'vant';
import { reactive, ref } from 'vue';
export default {
    name: 'Comments',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [TextWrapper.name]: TextWrapper,
        [Field.name]: Field,
        [Button.name]: Button,
    },
    setup() {
        const userMsg = reactive({
            username: '王梦梦',
            sex: '女',
            idCard: '5023165895236587978553',
            className: '高2022级1班',
            fatherName: '王某某',
            fatherPhone: '13454546434',
            motherName: '张某某',
            motherPhone: '13454546434',
        });
        const comments = ref('');
        return {
            userMsg,
            comments,
        };
    },
};
</script>
<style lang="less" scoped>
.des {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 13px;
    font-family: PingFang SC, serif;
    font-weight: 400;
    line-height: 9px;
    color: #999999;
    flex: 1;
    position: relative;
}
.has-line {
    position: relative;
    &::after {
        content: '';
        left: 34px;
        right: 0;
        height: 1px;
        position: absolute;
        bottom: 0;
        background: #f2f2f2;
    }
}
.btn-wrapper {
    margin-top: 59px;
    text-align: center;
    margin-bottom: 20px;
}
</style>
